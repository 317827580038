import { Divider, ListItem } from '@mui/material'
import { List, styled } from '@mui/material'
import useLocales from 'hooks/useLocales'
import { IPartyAlias } from '../../../../@types/party';
import { FormikProps } from 'formik';
import AliasItem from './AliasItem';

type Props = {
    aliases: IPartyAlias[]
    formik: FormikProps<any>
}

function AliasList({ aliases, formik }: Props) {
    const { translate } = useLocales()

    return (
        <RootList>
            <HeaderListItem>
                {translate('user.listOfAliases', 'List of aliases')}
            </HeaderListItem>
            <Divider component="li" />
            {aliases?.map((item) => <AliasItem key={item.id} alias={item} aliases={aliases} formik={formik} />)}
            <AliasItem aliases={aliases} formik={formik} />
        </RootList>
    )
}

export default AliasList



const HeaderListItem = styled(ListItem)(({ theme }) => ({
    // backgroundColor: theme.customPalette?.tableHeadColor,
    // color: "#fff",
    fontWeight: 600,
}))
const RootList = styled(List)(({ theme }) => ({
    padding: 0,
    width: '100%',
    borderRadius: 2,
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    backgroundColor: 'background.paper',
    color: "#212B36",
}))