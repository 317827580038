import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { IContractFolderItem } from "../../@types/contract";
import { axiosBaseQuery } from "redux/axiosBaseQuery";
import { ICreateFolder } from "../../@types/inbox";

export type IFetchContentListPayload = {
    folderId?: string,
    rowsPerPage?: number,
    page?: number,
    currentPageNumber?: number
}

export type IUpdateContentListPayload = {
    uuid: string,
    payload: ICreateFolder
}

export const contentApi = createApi({
    reducerPath: "API/CONTENT",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['contents'],
    endpoints(builder) {
        return {
            fetchContentList: builder.query<IContractFolderItem[], IFetchContentListPayload>({
                query({ folderId, rowsPerPage, page = 1, currentPageNumber }) {
                    let rowsPerPageValue = rowsPerPage ? rowsPerPage : 20;

                    let endpointURL = `/cs-api/contents?pageSize=${rowsPerPageValue || 20}&pageNumber=${currentPageNumber ? currentPageNumber : page
                        }`;

                    if (folderId) {
                        endpointURL = `/cs-api/contents?pageSize=${rowsPerPageValue || 20}&pageNumber=${currentPageNumber ? currentPageNumber : page
                            }&folderUuid=${folderId}`;
                    }

                    return {
                        url: endpointURL,
                    };
                },
                providesTags: (result, error, { folderId }) =>
                    result ? [{ type: 'contents', id: folderId }] : [],
            }),
            updateContent: builder.mutation<string, IUpdateContentListPayload>({
                query: ({ uuid, payload }) => ({
                    url: `/cs-api/folders/${uuid}`,
                    data: payload,
                    method: 'PUT',
                }),
                async onQueryStarted({ uuid, payload }, { dispatch, getState, queryFulfilled }) {
                    const queriesData = getState()['API/CONTENT'].queries;
                    let args: IFetchContentListPayload = {};

                    for (const key in queriesData) {
                        const data: any = queriesData[key]?.data;
                        const isMatch = data?.content.find((e: IContractFolderItem) => e.uuid === uuid);
                        if (isMatch) {
                            args = queriesData[key]?.originalArgs as any;
                        }
                    }

                    const patchResult = dispatch(
                        contentApi.util.updateQueryData('fetchContentList', args, (draft: any) => {

                            draft.content.map((item: IContractFolderItem) => {
                                if (item.uuid === uuid) {
                                    item.name = payload.name;
                                }
                                return item;
                            });
                        })
                    );

                    try {
                        await queryFulfilled;
                    } catch {
                        patchResult.undo();
                    }
                },
            }),
        };
    }
})

export const { useFetchContentListQuery, useUpdateContentMutation } = contentApi