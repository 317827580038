import { Box, Dialog, DialogContent, Divider, ListItem, styled } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import Iconify from 'components/Iconify';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { IPartyAlias } from '../../../../@types/party';
import { FormikProps } from 'formik';
import { useState } from 'react';
import InputControlText from 'components/reusable/input-controls/InputControlText';
import useLocales from 'hooks/useLocales';
import { DialogTitle } from '@mui/material';
import { DialogContentText } from '@mui/material';
import { DialogActions } from '@mui/material';
import { Button } from '@mui/material';

type Props = {
    aliases: IPartyAlias[]
    formik: FormikProps<any>
    alias?: IPartyAlias,
}

function AliasItem({ formik, alias, aliases }: Props) {
    const { setFieldValue } = formik
    const { translate } = useLocales()

    const [open, setOpen] = useState<boolean>(false)
    const [edit, setEdit] = useState<number | null>(null)
    const [aliasName, setAliasName] = useState<string>("")

    const handlePressEnterSave = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleAdd()
        }
    }
    const handlePressEnterEdit = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSave()
        }
    }

    const handleEdit = (item: IPartyAlias) => {
        setEdit(item.id)
        setAliasName(item.name)
    }

    const handleSave = () => {
        setFieldValue("aliases", aliases.map(e => {
            if (edit === e.id && aliasName) {
                return { ...e, name: aliasName }
            }
            return e
        }))
        setEdit(null)
        setAliasName("")
    }

    const handleChangeItem = (e: any) => {
        setAliasName(e.target.value)
    }

    const handleDelete = () => {
        setFieldValue("aliases", aliases.filter((v: IPartyAlias) => v.id !== alias?.id))
    }

    const handleAdd = () => {
        if (aliasName) {
            const item: IPartyAlias = {
                id: new Date().getTime(),
                name: aliasName
            }
            setAliasName("")
            console.log("item", item);

            setFieldValue("aliases", [...aliases, item])
        }
    }

    const handleClear = () => {
        setAliasName("")
    }

    let content = <>
        <LineListItem>
            <Box>
                <InputControlText value={aliasName} name="alias" size='small' label={translate("parties.enterNewAlias", "Enter a new alias")} onChange={handleChangeItem} onKeyDown={handlePressEnterSave} />
            </Box>
            <IconBox>
                <AddIcon color='info' onClick={handleAdd} />
                {aliasName && <CloseIcon onClick={handleClear} />}
            </IconBox>
        </LineListItem>
        <Divider component="li" />
    </>
    if (alias) {
        content = <>
            <LineListItem>
                <Box>
                    {edit === alias?.id ?
                        <InputControlText value={aliasName} name="alias" size='small' label={alias ? translate("parties.updateAliasName", "Update alias name") : translate("parties.enterNewAlias", "Enter a new alias")} onChange={handleChangeItem} onKeyDown={handlePressEnterEdit} />
                        : alias?.name}
                </Box>
                <IconBox>
                    {edit === alias?.id ? <SaveIcon color='info' onClick={handleSave} /> :
                        <Iconify onClick={() => handleEdit(alias)} icon={'eva:edit-fill'} sx={{ marginRight: 1, fontSize: '1.5rem', cursor: "pointer" }} />
                    }
                    <DeleteIcon onClick={() => setOpen(true)} />
                </IconBox>
            </LineListItem>
            <Divider component="li" />
        </>
    }

    return <>
        {content}
        <Dialog
            open={!!open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {translate("party.doYouWantToDeleteThisAlias", "Do you want to delete this alias?")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ pt: 2, pb: 0 }}>

                    {translate("party.aliasName", "Alias name")} : <Box component={"strong"}>{alias?.name}</Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>{translate("party.no", "No")}</Button>
                <Button onClick={handleDelete} autoFocus>
                    {translate("party.delete", "Delete")}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default AliasItem

const LineListItem = styled(ListItem)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between"
}))

const IconBox = styled(Box)(({ theme }) => ({
    "svg": {
        transition: "all .3s",
        marginLeft: "1rem",
        "&:hover": {
            cursor: "pointer",
            color: theme.palette.grey[500],
        }
    }
}))