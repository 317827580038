import { Box } from '@mui/material'
import useLocales from './useLocales'
import { useSnackbar } from 'notistack'

const useAPIError = () => {
    const { translate } = useLocales();
    const { enqueueSnackbar } = useSnackbar();

    const convertStringAsErrorKey = (str: string) => {
        const text = str
            .replace(/[^a-zA-Z0-9 ]/g, '') // Remove special characters
            .split(' ') // Split by spaces
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
            .join('');

        return `validationBackend.${text}`
    }
    const handleError = (error: any) => {
        console.error("Error message", error);

        const validationFailures = error?.data?.validationFailures || error?.response?.data?.validationFailures || []

        if (error?.status === 400 || error?.response?.status === 400 && validationFailures?.length) {
            enqueueSnackbar(<Box> {
                validationFailures.map((e: any, i: number) => <Box key={i} sx={{ my: .5 }}>- {
                    translate(convertStringAsErrorKey(e?.code), `${e?.code}`)
                }</Box>)
            } </Box>, { variant: "error" })
        } else {
            enqueueSnackbar(translate('error.serverError', 'Something went wrong!'), { variant: 'error' });
        }

    }

    return {
        handleError
    }
}

export default useAPIError