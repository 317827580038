// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ORGANIZATION = '/organization';
const ROOTS_USER = '/user';
const ROOTS_GROUP = '/group';
const ROOTS_CONTRACT = '/contract';
const ROOTS_CONTRACTS = '/contracts';
const ROOT_FOLDER = '/folders';
const ROOTS_CONTRACTABILITY = '/contractability';
const ROOTS_INBOX = '/inbox';
const ROOTS_PARTIES = '/parties';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  logout: path(ROOTS_AUTH, '/logout'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  companies: '/companies-list',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  about: '/about-us',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    // account: path(ROOTS_DASHBOARD, '/user/account'),
  },

  company: {
    root: path(ROOTS_DASHBOARD, '/company'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

export const PATH_ORGANIZATION = {
  root: ROOTS_ORGANIZATION,
  list: path(ROOTS_ORGANIZATION, '/list'),
  newOrg: path(ROOTS_ORGANIZATION, '/new'),
  edit: path(ROOTS_ORGANIZATION, '/edit/:id'),
  profile: path(ROOTS_ORGANIZATION, '/profile')
};

export const PATH_CONTRACTABILITY = {
  root: ROOTS_CONTRACTABILITY,
  users: path(ROOTS_CONTRACTABILITY, '/users'),
  organizations: path(ROOTS_CONTRACTABILITY, '/organizations'),
  contracts: path(ROOTS_CONTRACTABILITY, '/contracts'),
  updateOrg: path(ROOTS_CONTRACTABILITY, '/edit-organization/:id'),
};

export const PATH_USER = {
  root: ROOTS_USER,
  profile: path(ROOTS_USER, '/user/profile'),
  list: path(ROOTS_USER, '/list'),
  newUser: path(ROOTS_USER, '/new'),
  editUser: path(ROOTS_USER, '/edit/:id'),
};

export const PATH_GROUP = {
  root: ROOTS_GROUP,
  list: path(ROOTS_GROUP, '/list'),
  newGroup: path(ROOTS_GROUP, '/new'),
  editGroup: path(ROOTS_GROUP, '/edit/:id'),
};



export const PATH_CONTRACT = {
  root: ROOTS_CONTRACT,
  // list: path(ROOTS_CONTRACT, ""),
  // folder: path(ROOTS_CONTRACT, "/folder/:folderId"),
  // search: path(ROOTS_CONTRACT, ''),
  newContract: path(ROOTS_CONTRACT, '/new'),
  viewContract: path(ROOTS_CONTRACT, '/view/:id'),
  edit: path(ROOTS_CONTRACT, '/edit/:id'),
  test: path(ROOTS_CONTRACT, '/test'),

  //New pages
  newEdit: path(ROOTS_CONTRACT, '/_edit/:id'),
};

export const PATH_CONTRACTS = {
  root: ROOTS_CONTRACTS,
  search: path(ROOTS_CONTRACTS, '')
};
export const PATH_FOLDER = {
  root: ROOT_FOLDER,
  list: path(ROOT_FOLDER, ''),
  folder: path(ROOT_FOLDER, '/:folderId'),
};

export const PATH_INBOX = {
  root: ROOTS_INBOX,
  list: path(ROOTS_INBOX, '/list'),
  newInbox: path(ROOTS_INBOX, '/new'),
  edit: path(ROOTS_INBOX, '/edit/:id'),
};

export const PATH_PARTIES = {
  root: ROOTS_PARTIES,
  list: path(ROOTS_PARTIES, ''),
  edit: path(ROOTS_PARTIES, '/edit/:id'),
}