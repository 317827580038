import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
// config
// import { HEADER, NAVBAR } from '../../config';
import ListIcon from '@mui/icons-material/List';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Grid from '@mui/material/Grid';

import HomeIcon from '@mui/icons-material/Home';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useNavigate, useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useLocales from 'hooks/useLocales';

import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import {
  PATH_CONTRACT,
  PATH_ORGANIZATION,
  PATH_USER,
  PATH_GROUP,
  PATH_AUTH,
  PATH_DASHBOARD,
  PATH_INBOX,
  PATH_FOLDER,
  PATH_CONTRACTS,
} from 'routes/paths';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import useAuth from 'hooks/useAuth';

import { showFilter, hideFilter } from 'redux/slices/searchContract';
import { useSelector } from 'react-redux';

import jwtDecode from 'jwt-decode';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function MobileMenu() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const privateAxiosInstance = useAxiosPrivate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { logout, user } = useAuth();

  const handleLogout = async () => {
    const token = window.localStorage.getItem('accessToken');
    const refreshToken = window.localStorage.getItem('refreshToken');

    let userForwardURI = '/';
    if (token) {
      const tokenData: any = jwtDecode(token);
      const logoutResponse = await privateAxiosInstance.get(
        `/us-api/auth-detail/logout?username=${tokenData['cognito:username']}&token=${refreshToken}`
      );
      userForwardURI = logoutResponse?.data?.userForwardURI;
    }
    window.location.href = userForwardURI;

    await logout();
    navigate(PATH_AUTH.login, { replace: true });
  };

  // @ts-ignore
  const state = useSelector((st) => st.searchContract);

  const searchContract = () => {
    if (state.open) {
      hideFilter();
      navigate(PATH_CONTRACTS.search);
      showFilter();
    } else {
      navigate(PATH_CONTRACTS.search);
      showFilter();
    }
  };

  const userRole = user?.role?.name;
  const userFullName = user?.userInfo?.firstName + ' ' + user?.userInfo?.lastName;
  const theme = useTheme();
  const isVerifiedTheme = theme?.customPalette?.mode === 'verified';

  const handleOpenHelp = () => {
    if (isVerifiedTheme) {
      window.open('https://support.verified.eu/hc/en-us/sections/12354973701532-FAQ-Contract-Manager-CM', '_blank');
    } else {
      window.open('https://tagd.ai/support/', '_blank');
    }
  };
  const handleOpenSupport = () => {
    if (isVerifiedTheme) {
      window.location.href = 'mailto:support@verified.eu';
    } else {
      window.open('https://wkf.ms/3TwSu12', '_blank');
    }
  };

  const handleOpenServiceStatus = () => {
    window.open('https://tagd.checkly-dashboards.com', '_blank');
  };

  return (
    <>
      <Grid
        container
        direction="column-reverse"
        justifyContent="flex-end"
        alignItems="flex-end"
        paddingTop="1rem"
        position="fixed"
        right="5px"
        top="5px"
      >
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <ListIcon fontSize="large" />
        </IconButton>
      </Grid>
      <Menu
        style={{ overflow: 'scroll', height: '50rem' }}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigate('/dashboard/app')}>
          <HomeIcon /> &nbsp; {translate('navbar.dashboard', 'Dashboard')}
        </MenuItem>
        <MenuItem onClick={() => navigate(PATH_FOLDER.list)}>
          <InsertDriveFileIcon /> &nbsp; {translate('navbar.contractList', 'Contract list')}
        </MenuItem>
        <MenuItem onClick={() => navigate(PATH_CONTRACT.newContract)}>
          <AddIcon /> &nbsp; {translate('navbar.newContract', 'New contract')}
        </MenuItem>
        <MenuItem onClick={searchContract}>
          <SearchIcon /> &nbsp; {translate('navbar.searchContract', 'Search contract')}
        </MenuItem>
        <MenuItem onClick={() => navigate(PATH_INBOX.list)}>
          <AddIcon /> &nbsp; {translate('navbar.inboxLabel', 'Inbox')}
        </MenuItem>

        {userRole === 'SuperAdministrator' && (
          <>
            {/* org */}
            <Divider />
            <MenuItem>
              <AccountBalanceIcon /> &nbsp; <b> {translate('navbar.org', 'Organizations')}</b>
            </MenuItem>
            <MenuItem onClick={() => navigate(PATH_ORGANIZATION.newOrg)}>
              <span style={{ paddingLeft: '2rem' }}>
                {' '}
                {translate('navbar.newOrg', 'New organization')}{' '}
              </span>
            </MenuItem>
            <MenuItem onClick={() => navigate(PATH_ORGANIZATION.list)}>
              <span style={{ paddingLeft: '2rem' }}>
                {' '}
                {translate('navbar.orgList', 'Organizations list')}{' '}
              </span>
            </MenuItem>
          </>
        )}

        {/* users */}
        {userRole !== 'User' && (
          <>
            <Divider />
            <MenuItem>
              <PeopleAltIcon /> &nbsp; <b>{translate('navbar.users', 'Users')}</b>
            </MenuItem>
            <MenuItem
              onClick={() => navigate(PATH_USER.newUser, { state: { forceRefresh: true } })}
            >
              <span style={{ paddingLeft: '2rem' }}>
                {' '}
                {translate('navbar.newUsers', 'New user')}
              </span>
            </MenuItem>
            <MenuItem onClick={() => navigate(PATH_USER.list)}>
              <span style={{ paddingLeft: '2rem' }}>
                {' '}
                {translate('navbar.userList', 'User list')}{' '}
              </span>
            </MenuItem>
            <MenuItem onClick={() => navigate(PATH_GROUP.list)}>
              <span style={{ paddingLeft: '2rem' }}>
                {' '}
                {translate('navbar.userGroups', 'User groups')}{' '}
              </span>
            </MenuItem>
          </>
        )}

        {/* profile */}
        <Divider />
        <MenuItem>
          <AccountBoxIcon /> &nbsp; <b>{userFullName}</b>
        </MenuItem>
        <MenuItem onClick={() => navigate(PATH_DASHBOARD.user.profile)}>
          <span style={{ paddingLeft: '2rem' }}> {translate('navbar.profile', 'Profile')}</span>
        </MenuItem>

        <MenuItem onClick={handleOpenHelp}>
          <span style={{ paddingLeft: '2rem' }}>
            {' '}
            {translate('navbar.helpCenter', 'Help center')}{' '}
          </span>
        </MenuItem>
        <MenuItem onClick={handleOpenSupport}>
          <span style={{ paddingLeft: '2rem' }}>{translate('menuOption.support', 'Support')}</span>
        </MenuItem>

        {/* <MenuItem onClick={handleOpenServiceStatus}>
          <span style={{ paddingLeft: '2rem' }}>
            {translate('navbar.serviceStatus', 'Service status')}
          </span>
        </MenuItem> */}

        <MenuItem onClick={() => handleLogout()}>
          <span style={{ paddingLeft: '2rem' }}> {translate('navbar.logout', 'Logout')} </span>
        </MenuItem>
      </Menu>
    </>
  );
}
